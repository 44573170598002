const emailRegex = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
export const isValidEmail = (str) => emailRegex.test(str);

export const reservationStatusList = [
  { value: 'new', label: '已下訂' },
  { value: 'dispatching', label: '廠商媒合中' },
  { value: 'dispatched', label: '已媒合廠商' },
  { value: 'accepted', label: '廠商已接單' },
  { value: 'quoting', label: '報價已提供' },
  { value: 'quotationConfirmed', label: '報價已確認' },
  { value: 'pending-ps1', label: '待付款' },
  { value: 'established', label: '訂單確立' },
  { value: 'staffAssigned', label: '已指派服務人員' },
  { value: 'staffArrived', label: '服務人員已到現場' },
  { value: 'processing', label: '服務人員服務中' },
  { value: 'processed', label: '服務完成' },
  { value: 'pending-ps3', label: '待付款' },
  { value: 'acceptanceChecked', label: '驗收完成' },
  { value: 'completed', label: '訂單完成' },
  { value: 'cancelled', label: '已取消' },
  { value: 'denied', label: '評估後不施作' },
  { value: 'error', label: '錯誤' }
];

export const reservationCompletedState = ['completed', 'cancelled', 'denied'];

export const findStateIndex = (state) => reservationStatusList
  .findIndex(item => item.value === state);

export const deniedMethods = (setting) => {
  const methods = [
    { key: 'isExcludeAutoDispatch', text: '訂單不媒合廠商' },
    { key: 'isForbidLogin', text: '禁止登入' },
    { key: 'isOnlyCardPayment', text: '僅能使用信用卡' }
  ];
  return methods
    .filter(method => setting[method.key])
    .map(method => method.text)
    .join(',');
};

export const removePrivateProperties = (data) => {
  // remove properties that names are starting with _
  return _.pickBy(data || {}, (value, key) => !_.startsWith(key, '_'));
};

export const removeInnerProperties = (data) => {
  // remove properties that names are starting with _
  // AND remvoe undefined values
  // remvoe null values，因為會員資料下載後端會擋 userId 為 null 的 URL
  return _.pickBy(data || {}, (value, key) => !_.startsWith(key, '_') && !_.eq(undefined, value) && !_.eq(null, value));
};

export const getOrigin = (source) => {
  if (source === 'lsApp' || source === 'lsWeb') {
    return source.replace('ls', '').toLowerCase();
  } else if (source === 'sinyi') {
    return '信義房屋';
  } else if (!source) {
    return '居家';
  } else {
    return source;
  }
};

export function generateTableSettings ($scope, {
  index,
  columns,

  disableMovement,
  onMoved,

  disableDeletion,
  onDelete,

  disableAdd,
  onAdd,

  ...rest
}) {
  const item = $scope.currentActionItem;
  const settings = {
    type: 'table',
    index,
    ...rest
  };

  if (columns) {
    /**
     * column.header：標頭文字。
     * column.index：欄位的對應資料的 key。
     * column.type：預設值為 text，指定要用的 template, Action.html 裡的 template 都可以用。
     * column.hideColumn：隱藏欄位。
     * rest：剩餘設定都會傳給 template。
     */
    settings.columns = columns.map(({
      index,
      header,
      type,
      ...rest
    }) => ({
      index,
      header,
      type: type ? `input-${type}` : 'input-text',
      _isTemplateInColumn: true,
      ...rest
    }));
  }

  if (!disableMovement) {
    settings.onMoved = onMoved || ((idx) => item[index].splice(idx, 1));
  }
  settings.canMove = !!settings.onMoved;

  if (!disableDeletion) {
    settings.onDelete = onDelete || ((idx) => item[index].splice(idx, 1));
  }
  settings.canDelete = !!settings.onDelete;

  if (!disableAdd) {
    settings.onAdd = onAdd || (() => item[index].push({}));
  }
  settings.canAdd = !!settings.onAdd;

  return settings;
}
